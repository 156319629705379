var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"my-8 listing-body"},[_c('div',{staticClass:"t-body"},[(_vm.get_posted_jobs_loading)?[_c('v-row',{staticClass:"base-card pa-0 data-row my-5 my-md-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-3 px-md-4 py-md-5",attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1)]:(
        !_vm.get_posted_jobs_loading && _vm.get_posted_jobs.results.length <= 0
      )?[_c('v-row',{staticClass:"base-card t-body-no-jobs"},[_c('v-col',{staticClass:"d-flex align-center justify-center flex-column pa-16",attrs:{"cols":"12"}},[_c('DataNotFound',{attrs:{"title":_vm.get_no_data_msg()}}),_c('v-btn',{staticClass:"my-10 base-submit-button t-body-no-jobs-post-job-btn",attrs:{"to":"jobs/post-job"}},[_vm._v(" "+_vm._s(_vm.$t("nav-sidebar.post-job"))+" ")])],1)],1)]:[_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.get_posted_jobs.results,"page":_vm.page,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('th',{key:index},[_c('div',{staticClass:"d-flex align-start flex-column"},[_c('p',{staticClass:"mb-0 base-section-sub-title font-weight-bold"},[_vm._v(" "+_vm._s(header.title)+" ")])])])}),0)])]},proxy:true},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.jid))]),_c('td',[_vm._v(_vm._s(_vm.format_date(item.created_at)))]),_c('td',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.job_title))]),_c('td',[_vm._v(" "+_vm._s(item.industry)+" ")]),_c('td',[_vm._v(_vm._s(item.sector))]),_c('td',[(item.candidates_loading)?[_c('v-skeleton-loader',{staticClass:"d-flex",attrs:{"type":"avatar@6"}})]:(
                    !item.candidates_loading &&
                    item.matching_candidates.length <= 0
                  )?[_c('p',{staticClass:"base-body-text mb-0 no-applicants"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.job-listing.no-candidates"))+" ")])]:[_c('ListingApplicants',{attrs:{"job_id":item.jid,"job_title":item.job_title,"applicants":item.matching_candidates}})]],2),_c('td',[_c('v-chip',{staticClass:"finished-status text-capitalize"},[_vm._v(" "+_vm._s(item.status)+" ")])],1),_c('td',[_c('ListingActions',{attrs:{"job":item},on:{"edit_job":_vm.activate_edit_job_mode,"view_job":_vm.activate_view_job_mode,"job_removed":_vm.job_removed}})],1)])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"t-footer"},[_c('v-row',{staticClass:"mt-4",attrs:{"align":"center","justify":"center"}},[(_vm.loading)?[_c('v-skeleton-loader',{staticClass:"d-flex align-center mx-4 pagination-loader",attrs:{"tile":true,"type":"avatar@4"}})]:(!_vm.get_posted_jobs_loading)?[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('v-pagination',{staticClass:"pagination-wrapper",attrs:{"color":"primary","length":Math.ceil(_vm.get_posted_jobs.total / 10),"total-visible":6,"prev-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                          ? 'mdi-arrow-left'
                          : 'mdi-arrow-right',"next-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                          ? 'mdi-arrow-right'
                          : 'mdi-arrow-left',"disabled":_vm.get_posted_jobs.total <= 10},on:{"input":_vm.extract_jobs},model:{value:(_vm.get_posted_jobs.pagination),callback:function ($$v) {_vm.$set(_vm.get_posted_jobs, "pagination", $$v)},expression:"get_posted_jobs.pagination"}})],1)]:_vm._e()],2)],1)]},proxy:true}])})],1)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }