














































































import Vue from "vue";
import AutoCompleteJobSearch from "@/components/shared/jobs/AutoCompleteJobSearch.vue";
import { get_color_by_name } from "@/utils/colors";
import { SearchAutocompleteJobPayload } from "@/store/modules/recruiter/interfaces";
import { mapGetters, mapMutations } from "vuex";
import {
  JOBS_POSTED_BY_RECRUITER,
  JOBS_POSTED_BY_RECRUITER_LOADING,
  JOB_LISTING_DATE_FILTER,
  JOB_LISTING_SEARCHED_JOB_FILTER
} from "@/store/modules/recruiter/constants";
import moment from "moment";

export default Vue.extend({
  name: "JobListingHeader",
  components: { AutoCompleteJobSearch },
  data() {
    return {
      date_picker_menu: false as boolean,
      date_range_value: null as string | null,
      search_job_title: null as SearchAutocompleteJobPayload | null
    };
  },
  mounted() {
    if (this.get_posted_jobs.date_filter.length === 2)
      this.format_date_range(this.get_posted_jobs.date_filter);
  },
  watch: {
    get_job_listing_date_filter(n) {
      if (n.length > 0 && n.length < 3) this.format_date_range(n);
      else this.date_range_value = null;
    }
  },
  computed: {
    ...mapGetters("recruiter", {
      get_posted_jobs: JOBS_POSTED_BY_RECRUITER,
      fetch_posted_jobs_loading: JOBS_POSTED_BY_RECRUITER_LOADING,
      get_job_listing_date_filter: JOB_LISTING_DATE_FILTER
    })
  },
  methods: {
    ...mapMutations("recruiter", {
      set_job_date_filter: JOB_LISTING_DATE_FILTER,
      set_searched_job_title: JOB_LISTING_SEARCHED_JOB_FILTER
    }),
    // Function to process job title received from autocomplete search filed
    async set_autocomplete_search_value(
      value: SearchAutocompleteJobPayload | null
    ) {
      this.set_searched_job_title(value);
    },
    // Transform calendar icon
    transform_calender_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute("stroke", get_color_by_name("primary"));
      return icon;
    },
    // Format selected dates ranges & fetch jobs if from & to both are selected
    async format_date_range(dates: string[]) {
      if (dates.length === 1)
        this.date_range_value = moment(dates[0]).format("DD MMM YYYY");
      else if (dates.length === 2) {
        this.date_range_value = moment(dates[0]).format("DD MMM YYYY");
        // this.date_range_value += `<span class="base-body-text text-lowercase">&nbsp; to &nbsp;</span>`;
        this.date_range_value +=
          "  to  " + moment(dates[1]).format("DD MMM YYYY");
        this.date_picker_menu = false;
      }
    },
    async clear_dates() {
      this.set_job_date_filter([]);
    }
  }
});
