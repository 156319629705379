











import Vue from "vue";
import ListingTBody from "@/components/recruiter/jobs/job_listing/ListingTBody.vue";
import JobListingHeader from "@/components/recruiter/jobs/job_listing/JobListingHeader.vue";
import { JobsWithCandidates } from "@/store/modules/recruiter/interfaces";
export default Vue.extend({
  name: "JobListing",
  components: {
    JobListingHeader,
    ListingTBody
  },
  data() {
    return {
      job: null as JobsWithCandidates | null,
      update_job_loading: false as boolean
    };
  }
});
