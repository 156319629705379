var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"d-flex align-center action-buttons"},[_c('v-tooltip',{attrs:{"top":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn view_btn",attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.$emit('view_job', _vm.job)}}},'v-btn',attrs,false),on),[_c('inline-svg',{attrs:{"width":"25","height":"25","src":_vm.view_icon,"transformSource":_vm.transform_view_icon}})],1)]}}])},[_c('span',[_vm._v("View Job")])]),_c('v-tooltip',{attrs:{"top":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn share_btn",attrs:{"icon":"","color":"purple-light"},on:{"click":_vm.redirect_to_view_job_candidates}},'v-btn',attrs,false),on),[_c('inline-svg',{attrs:{"width":"25","height":"25","src":_vm.share_icon,"transformSource":_vm.transform_share_icon}})],1)]}}])},[_c('span',[_vm._v("View Candidates for the "+_vm._s(_vm.job.job_title || "")+" Job ")])]),_c('v-tooltip',{attrs:{"top":"","color":"light-blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn update_btn",attrs:{"icon":"","color":"primary-light"},on:{"click":function($event){return _vm.$emit('edit_job', _vm.job)}}},'v-btn',attrs,false),on),[_c('inline-svg',{attrs:{"width":"25","height":"25","src":_vm.update_icon,"transformSource":_vm.transform_update_icon}})],1)]}}])},[_c('span',[_vm._v("Edit Job")])]),_c('v-tooltip',{attrs:{"top":"","color":"danger"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn delete_btn",attrs:{"icon":"","color":"danger","transformSource":_vm.transform_trash_icon},on:{"click":function($event){_vm.delete_dialog = true}}},'v-btn',attrs,false),on),[_c('inline-svg',{attrs:{"width":"25","height":"25","src":_vm.delete_icon}})],1)]}}])},[_c('span',[_vm._v("Delete "+_vm._s(_vm.job.job_title || "")+" Job")])]),[_c('v-dialog',{attrs:{"max-width":"700px","transition":"dialog-top-transition"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('DeleteItem',{attrs:{"loading":_vm.recruiter_loading,"title":_vm.$t('recruiter.job-listing.del-job-title'),"submit_btn_text":_vm.$t('recruiter.job-listing.del-job-btn')},on:{"cancel":_vm.dialog_action,"submit":_vm.dialog_action}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }